import React from "react";
import { Drop, Footer, Seo } from "../components";
import {
	AboutUs,
	Contact,
	PaintProtectionService,
	PPFService,
	Testimonial,
	WhyUs,
} from "../containers";

const CommercialWindowTinting = () => {
	return (
		<div className='w-screen'>
			<Seo
				title='Premium Paint Protection Film - Keep Your Car Looking New'
				description='Protect your vehicle from scratches and dirt with paint protection film. Our films offer a virtually invisible layer of protection that helps preserve the original look and finish of your car. Get worry-free protection for the life of your vehicle.'
			/>
			<PaintProtectionService />
			<Testimonial />
			<WhyUs />
			<PPFService />
			<Contact />
			<AboutUs />
			<Drop />
			<Footer />
		</div>
	);
};

export default CommercialWindowTinting;
